import React, { useState, useEffect, useRef, useMemo } from "react"
import { graphql } from "gatsby"
import { getUser, isLoggedIn } from "../../../services/auth"
import Layout from "../../../templates/Page"
import Header from "../../ContentBuilder/Header"
import { Swiper, SwiperSlide } from "swiper/react"
import { Breadcrumb, Container, Row, Col } from "react-bootstrap"
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  HashNavigation,
} from "swiper/core"
import CabinetryRelatedItems from "./CabinetryRelatedItems"
import ShowMoreText from "react-show-more-text"
import CreateBoardModal from "../../ContentBuilder/Modal/CreateBoardModal"
import ProductThumbnailsData from "../../ContentBuilder/SwiperList/ProductThumbnailsData"
import {
  getRecentPages,
  setRecentPages,
} from "../../../services/account-services"
import RecentViews from "../../RecentViews"
import { connect } from "react-redux"
import { getBoardFromLocalStorage } from "../../../state/board"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, HashNavigation])

const CabinetryDetail = ({ data, currentBoards, dispatch }) => {
  const swiper_params = {
    initialSlide: 1,
    spaceBetween: 10,
    navigation: {
      prevEl: ".swiper-button-prev-unique",
      nextEl: ".swiper-button-next-unique",
    },
    breakpoints: {
      1200: {
        slidesPerView: 5,
      },
      1100: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      },
    },
  }

  const [item, setItem] = useState({})
  const [variation, setVariation] = useState({})
  const [gallery, setGallery] = useState([])
  const [globalConfig, setGlobalConfig] = useState({})
  const [activeObjectID, setActiveObjectID] = useState(null)
  const [isSaved, setIsSaved] = useState(false)
  var vid = null
  if (typeof window !== "undefined") {
    const search = typeof window !== "undefined" ? window.location.search : "/"
    const params = new URLSearchParams(search)
    vid = params.get("vid")
  }
  
  var _allVariations = []
  const variations = item.Variations
  const valid_status = ["Active"]
  
  useEffect(() => {
    dispatch(getBoardFromLocalStorage())
  }, [])

  useEffect(() => {
    let found = false
    for (const board of currentBoards) {
      const items = JSON.parse(board.Items)
      for (const key in items) {
        if (!found && key.includes("Cabinetry") && items[key].ID == item.objectID && items[key].VariantID == variation.objectID) {
          setIsSaved(true)
          found = true
        }
      }
    }
    if (!found) setIsSaved(false)
  }, [currentBoards, item, variation])

  useEffect(() => {
    if (data) {
      setGlobalConfig(data.silverStripeDataObject.UndigitalGlobalConfig)
      let _item = data.allSilverStripeDataObject.nodes[0].UndigitalCabinetry
      setItem(_item)
      if (typeof _item.FeaturedVariation !== "undefined") {
        let _variation = _item.FeaturedVariation.UndigitalCabinetryVariation

        let found = false
        for (const board of currentBoards) {
          const items = JSON.parse(board.Items)
          for (const key in items) {
            if (!found && key.includes("Cabinetry") && items[key].ID == _item.objectID && items[key].VariantID == _variation.objectID) {
              setIsSaved(true)
              found = true
            }
          }
        }
        if (!found) setIsSaved(false)

        if (activeObjectID === null) {
          setActiveObjectID(_variation.objectID)
        }
        let imagePosition = _variation.imagePosition
        let _featureStyles = ""
        let _styles = _variation.Styles
        let _stylesArr = []
        if (_styles !== "undefined" && _styles) {
          var a = 0
          for (; _styles[a];) {
            _stylesArr[a] = _styles[a].UndigitalProductStyle.name
            a++
          }
          _featureStyles = _stylesArr.join(", ")
        }
        _variation.StylesText = _featureStyles

        let rrp = _variation.rrPrice
        rrp = parseFloat(Math.round(rrp * 1000) / 1000).toFixed(2)
        _variation.formattedPrice =
          "$" + rrp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        setVariation(_variation)
        let galleryItems = _variation.Gallery
        let _gallery = []
        if (galleryItems !== null) {
          var j = 0
          for (; galleryItems[j];) {
            let galleryItem = galleryItems[j].UndigitalGalleryImage
            galleryItem.imagePosition = imagePosition
            _gallery.push(galleryItem)
            j++
          }
          setGallery(_gallery)
        }
      }
      if (typeof variations !== "undefined" && variations) {
        var i = 0
        for (; variations[i];) {
          let _variation = variations[i].UndigitalCabinetryVariation
          let _variation_id = _variation.objectID
        if(_variation_id == vid){
              setVariation(_variation)
              updateVariation(_variation)
          }
          let isValid = valid_status.includes(variation.status)
          if (isValid) {
            let rrpPrice = parseFloat(
              Math.round(_variation.rrPrice * 1000) / 1000
            ).toFixed(2)
            _variation.formattedPrice =
              "$" + rrpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

            let stylesName = ""
            let styles = _variation.Styles
            let stylesArr = []
            if (typeof styles !== "undefined" && styles) {
              var e = 0
              for (; styles[e];) {
                if (styles[e].UndigitalProductStyle.name !== "") {
                  stylesArr[e] = styles[e].UndigitalProductStyle.name
                }
                e++
              }
              stylesName = stylesArr.join(", ")
            }

            let combinedName = []
            if (_variation.name !== "") combinedName[0] = _variation.name
            if (stylesName !== "") {
              combinedName[1] = stylesName
            }

            _variation.StylesText = stylesName
            _variation.combinedName = combinedName.join(", ")
            _allVariations[i] = _variation
          }
          i++
        }
      }
    }
  }, [variations])

  var designers = ""
  var designersArr = []
  var designersList = item.Designers
  if (designersList) {
    designersList.forEach(function (item, index) {
      designersArr.push(item.UndigitalDesigner.name)
    })
    designers = designersArr.join(", ")
  }

  var disclaimers = ""
  var disclaimerArr = []
  var disclaimerList = item.Disclaimer
  if (disclaimerList) {
    disclaimerList.forEach(function (item, index) {
      disclaimerArr.push(item.UndigitalTextAsset.content)
    })
    disclaimers = disclaimerArr.join(", ")
  }
  

  const [showCreateBoard, setShowCreateBoard] = useState(false)
  if (typeof window !== "undefined") {
    if (window.location.hash) {
      var hash = window.location.hash.split("#")[1]
    }
  }

  const showAddBoard = () => {
    if (isLoggedIn()) {
      setShowCreateBoard(true)
    } else {
      if (typeof window !== "undefined") {
        window.location.href = "/login/"
      }
    }
  }

  const updateVariation = variation => {
    let found = false
    for (const board of currentBoards) {
      const items = JSON.parse(board.Items)
      for (const key in items) {
        if (!found && key.includes("Cabinetry") && items[key].ID == item.objectID && items[key].VariantID == variation.objectID) {
          setIsSaved(true)
          found = true
        }
      }
    }
    if (!found) setIsSaved(false)
    setVariation(variation)
    setActiveObjectID(variation.objectID)
    if (typeof window !== "undefined") {
        window.history.replaceState(null, null, '?vid=' + variation.objectID)
    }
    let imagePosition = variation.imagePosition

    let galleryItems = variation.Gallery
    let _gallery = []
    if (galleryItems !== null) {
      var j = 0
      for (; galleryItems[j];) {
        let galleryItem = galleryItems[j].UndigitalGalleryImage
        galleryItem.imagePosition = variation.imagePosition
        _gallery.push(galleryItem)
        j++
      }
      setGallery(_gallery)
    }
  }

  if (item && item.status === "Active") {
    const recentPages = getRecentPages()
    if(vid){
        if(variation && (typeof variation.status !== 'undefined' && variation.status == 'Active')){
            item.variant = variation   
            setRecentPages([
                { ...item, className: 'Cabinetry' },
                ...recentPages.filter(function (rp) {
                      return !((rp.className == "Cabinetry" && rp.objectID == item.objectID) && (typeof rp.variant !== 'undefined' && rp.variant.objectID == item.variant.objectID))
              }),
            ])
        }
    }else{
        setRecentPages([
            { ...item, className: 'Cabinetry' },
            ...recentPages.filter(function (rp) {
                  return !((rp.className == "Cabinetry" && rp.objectID == item.objectID) && (typeof rp.variant == 'undefined'))
          }),
        ])
    }
  }

  const [customData, setCustomData] = useState(variation)
  const [loaded, setLoaded] = useState(false)
  const imgElement = useRef(null)

  useEffect(() => {
    setCustomData(variation)
  }, [variation])

  const createBoardPopup = useMemo(() => {
    return <CreateBoardModal
      show={showCreateBoard}
      handleClose={() => {
        setShowCreateBoard(false)
      }}
      objectClass="Cabinetry"
      objectID={item.objectID}
      variantID={variation.objectID}
    />
  }, [currentBoards, showCreateBoard, item, variation])

  return (
    <Layout hideHeader>
      {createBoardPopup}
      <Header isLogin />
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/explore/">Explore</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/explore/cabinetry/">Cabinetry</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {item.name}
            </li>
          </ol>
        </nav>

        <div className="mt-30 mb-30 border-bottom-7px">
          <div className="row align-items-center">
            <div className="col-md">
              <h1 className="product-name-title">{item.name}</h1>
            </div>
            <div className="col-md-auto">
              <button type="button" className="button-link">
                <div
                  className="d-flex align-items-center"
                  onClick={() => showAddBoard()}
                >
                  {isSaved ? "Added to board" : "Add to board"}
                  {isSaved ? (
                    <svg
                      className="text-black ml-3"
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                    >
                      <path
                        id="Path_878"
                        data-name="Path 878"
                        d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z"
                        transform="translate(21.982 6.975)"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="1.5"
                      />
                    </svg>
                  ) : (
                    <svg
                      focusable="false"
                      width="30px"
                      height="30px"
                      className="text-black ml-3"
                    >
                      <use xlinkHref="/img/icons.svg#icon-heart"></use>
                    </svg>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
        {gallery.length > 0 ? (
          <div className="wrap-section row mt-5">
            <Col lg={{ span: 8, order: 1 }} xs={{ span: 12, order: 1 }}>
              <div className="d-flex flex-column flex-sm-row justify-content-between wrap-section border-bottom-4px feature-title">
                <h3 className="mb-0">
                  {variation ? variation.name : item.name}
                </h3>
              </div>
              <ProductThumbnailsData gallery={gallery} />
              {_allVariations.length > 1 && (
                <div className="other-available-wrap">
                  <h3 className="main-title">Other available options: </h3>
                  <div className="other-available">
                    <Swiper navigation {...swiper_params}>
                      {_allVariations.map((variationItem, idx) => (
                        <SwiperSlide
                          key={`variation${variationItem.objectID}`}
                          data-hash={`v${variationItem.objectID}`}
                        >
                          <div
                            onClick={() => updateVariation(variationItem)}
                            className={`clickable product-items ${variationItem.objectID}`}
                          >
                            <div
                              className={`product-items-thumb ${variationItem.objectID === activeObjectID
                                ? "active"
                                : ""
                                } ${variationItem.imagePosition}`}
                            >
                              <img
                                alt=""
                                src={variationItem.featureImagePath}
                                alt=""
                              />
                            </div>
                            <p className="product-items-desc">
                              {variationItem.name}
                              {globalConfig.hidePricingGlobally == 0 && (
                                <>
                                  {variationItem.name ? " | " : ""}
                                  {variationItem.formattedPrice}
                                </>
                              )}
                            </p>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="swiper-button-prev-unique"></div>
                    <div className="swiper-button-next-unique"></div>
                  </div>
                </div>
              )}
            </Col>
            <Col lg={{ span: 4, order: 2 }} xs={{ span: 12, order: 2 }}>
              <h3 className="mb-1 border-bottom-4px">Information</h3>
              <div className="list-group list-group-flush mb-4">
                {globalConfig.hidePricingGlobally == 0 && (
                  <div className="list-group-item px-0">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="mb-0">Price from</h5>
                      </div>
                      <div className="col-auto">
                        <p className="h4">{variation.formattedPrice}</p>
                      </div>
                    </div>
                  </div>
                )}
                {variation.StylesText && (
                  <div className="list-group-item px-0">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="mb-0">Style</h5>
                      </div>
                      <div className="col-auto">
                        <p className="font-weight-medium">
                          {variation.StylesText}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {designers && (
                  <div className="list-group-item px-0">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="mb-0">Designer</h5>
                      </div>
                      <div className="col-auto">
                        <p className="font-weight-medium">{designers}</p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="list-group-item px-0">
                  <h5 className="mb-0">Specs</h5>
                  <ShowMoreText
                    more="Read more"
                    less="Read less"
                    className="content-wrap"
                    anchorClass="button-link d-block mt-1 text-right"
                    expanded={false}
                    width={0}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: variation.specs }}
                    />
                  </ShowMoreText>
                </div>
                <div className="list-group-item px-0">
                  <h5 className="mb-0">About</h5>
                  <ShowMoreText
                    more="Read more"
                    less="Read less"
                    className="content-wrap"
                    anchorClass="button-link d-block mt-1 text-right"
                    expanded={false}
                    width={0}
                  >
                    <p>{item.description}</p>
                    <p>
                      Specs: {item.minSize} - {item.maxSize}
                    </p>
                  </ShowMoreText>
                </div>
                <div className="list-group-item px-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="mb-0">Package</h5>
                    </div>
                    <div className="col-auto">
                      <p className="font-weight-medium">{variation.costType}</p>
                    </div>
                  </div>
                </div>
                {disclaimers && (
                  <div className="list-group-item px-0">
                    <h5 className="mb-0">Disclaimer</h5>
                    <ShowMoreText
                      more="Read more"
                      less="Read less"
                      className="content-wrap"
                      anchorClass="button-link d-block mt-1 text-right"
                      expanded={false}
                      width={0}
                    >
                      <span dangerouslySetInnerHTML={{ __html: disclaimers }} />
                    </ShowMoreText>
                  </div>
                )}
              </div>
              <div className="row gutters-10 mt-30">
                <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                  <button
                    type="button"
                    className="btn btn-outline-black btn-block btn-lg px-2 my-1"
                  >
                    <div
                      className="d-flex align-items-middle justify-content-center"
                      onClick={() => showAddBoard()}
                    >
                      <svg
                        focusable="false"
                        width="24px"
                        height="24px"
                        className="mr-2"
                      >
                        <use xlinkHref="/img/icons.svg#icon-heart"></use>
                      </svg>
                      {isSaved ? "Added to board" : "Add to board"}
                    </div>
                  </button>
                </div>
                <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                  <a
                    href="/contact-us/"
                    className="btn btn-black btn-block btn-lg px-2 my-1"
                  >
                    Enquire now
                  </a>
                </div>
              </div>
            </Col>
          </div>
        ) : (
          <div className="wrap-section row mt-5">
            <div className="col-md-8">
              <div className="d-flex flex-column flex-sm-row justify-content-between wrap-section border-bottom-4px feature-title">
                <h3 className="mb-0">
                  {variation ? variation.name : item.name}
                </h3>
              </div>
              <div
                className={`landing-detail-image-wrap mb-4 ${variation.imagePosition
                  } ${!variation.featureImagePath ? " no-image" : ""}`}
              >
                {variation.featureImagePath && (
                  <a
                    src={variation.featureImagePath}
                    className="d-block main-img pointer"
                    data-fancybox="gallery"
                    data-width={
                      loaded && imgElement.current.src.slice(-3) === "svg"
                        ? imgElement.current.naturalWidth * 9
                        : ""
                    }
                    data-height={
                      loaded && imgElement.current.src.slice(-3) === "svg"
                        ? imgElement.current.naturalHeight * 9
                        : ""
                    }
                  >
                    <img
                      src={variation.featureImagePath}
                      alt=""
                      ref={imgElement}
                      onLoad={() => setLoaded(true)}
                    />
                  </a>
                )}
              </div>
              {_allVariations.length > 1 && (
                <div className="other-available-wrap">
                  <h3 className="main-title">Other available options: </h3>
                  <div className="other-available">
                    <Swiper navigation {...swiper_params}>
                      {_allVariations.map((variationItem, idx) => (
                        <SwiperSlide
                          key={`variation${variationItem.objectID}`}
                          data-hash={`v${variationItem.objectID}`}
                        >
                          <div
                            onClick={() => updateVariation(variationItem)}
                            className={`clickable product-items ${variationItem.objectID}`}
                          >
                            <div
                              className={`product-items-thumb ${variationItem.objectID === activeObjectID
                                ? "active"
                                : ""
                                } ${variationItem.imagePosition}`}
                            >
                              <img
                                alt=""
                                src={variationItem.featureImagePath}
                                alt=""
                              />
                            </div>
                            <p className="product-items-desc">
                              {variationItem.name}
                              {globalConfig.hidePricingGlobally == 0 && (
                                <>
                                  {variationItem.name ? " | " : ""}
                                  {variationItem.formattedPrice}
                                </>
                              )}
                            </p>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="swiper-button-prev-unique"></div>
                    <div className="swiper-button-next-unique"></div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-4">
              <h3 className="mb-1 border-bottom-4px">Information</h3>
              <div className="list-group list-group-flush mb-4">
                {globalConfig.hidePricingGlobally == 0 && (
                  <div className="list-group-item px-0">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="mb-0">Price from</h5>
                      </div>
                      <div className="col-auto">
                        <p className="h4">{variation.formattedPrice}</p>
                      </div>
                    </div>
                  </div>
                )}
                {variation.StylesText && (
                  <div className="list-group-item px-0">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="mb-0">Style</h5>
                      </div>
                      <div className="col-auto">
                        <p className="font-weight-medium">
                          {variation.StylesText}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {designers && (
                  <div className="list-group-item px-0">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="mb-0">Designer</h5>
                      </div>
                      <div className="col-auto">
                        <p className="font-weight-medium">{designers}</p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="list-group-item px-0">
                  <h5 className="mb-0">Specs</h5>
                  <ShowMoreText
                    more="Read more"
                    less="Read less"
                    className="content-wrap"
                    anchorClass="button-link d-block mt-1 text-right"
                    expanded={false}
                    width={0}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: variation.specs }}
                    />
                  </ShowMoreText>
                </div>
                <div className="list-group-item px-0">
                  <h5 className="mb-0">About</h5>
                  <ShowMoreText
                    more="Read more"
                    less="Read less"
                    className="content-wrap"
                    anchorClass="button-link d-block mt-1 text-right"
                    expanded={false}
                    width={0}
                  >
                    <p>{item.description}</p>
                    <p>
                      Specs: {item.minSize} - {item.maxSize}
                    </p>
                  </ShowMoreText>
                </div>
                <div className="list-group-item px-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="mb-0">Package</h5>
                    </div>
                    <div className="col-auto">
                      <p className="font-weight-medium">{variation.costType}</p>
                    </div>
                  </div>
                </div>
                {disclaimers && (
                  <div className="list-group-item px-0">
                    <h5 className="mb-0">Disclaimer</h5>
                    <ShowMoreText
                      more="Read more"
                      less="Read less"
                      className="content-wrap"
                      anchorClass="button-link d-block mt-1 text-right"
                      expanded={false}
                      width={0}
                    >
                      <span dangerouslySetInnerHTML={{ __html: disclaimers }} />
                    </ShowMoreText>
                  </div>
                )}
              </div>

              <div className="row gutters-10 mt-30">
                <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                  <button
                    type="button"
                    className="btn btn-outline-black btn-block btn-lg px-2 my-1"
                  >
                    <div
                      className="d-flex align-items-middle justify-content-center"
                      onClick={() => showAddBoard()}
                    >
                      <svg
                        focusable="false"
                        width="24px"
                        height="24px"
                        className="mr-2"
                      >
                        <use xlinkHref="/img/icons.svg#icon-heart"></use>
                      </svg>
                      {isSaved ? "Added to board" : "Add to board"}
                    </div>
                  </button>
                </div>
                <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                  <a
                    href="/contact-us/"
                    className="btn btn-black btn-block btn-lg px-2 my-1"
                  >
                    Enquire now
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        <CabinetryRelatedItems id={item.objectID} vid={vid} />
        <RecentViews />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    silverStripeDataObject(
      className: { eq: "Undigital__Objects__GlobalConfig" }
    ) {
      UndigitalGlobalConfig {
        hidePricingGlobally
      }
    }
    allSilverStripeDataObject(
      filter: {
        UndigitalCabinetry: {
          urlSegment: { eq: $slug }
          status: { in: ["Active", "Draft"] }
        }
      }
    ) {
      nodes {
        UndigitalCabinetry {
          status
          name
          description
          minSize
          maxSize
          urlSegment
          objectID
          Disclaimer {
            UndigitalTextAsset {
              content
            }
          }
          Designers {
            UndigitalDesigner {
              name
            }
          }
          FeaturedVariation {
            UndigitalCabinetryVariation {
              name
              status
              rrPrice
              featureImagePath
              imagePosition
              specs
              costType
              objectID
              Styles {
                UndigitalProductStyle {
                  name
                }
              }
              Gallery {
                UndigitalGalleryImage {
                  imagePath
                  caption
                }
              }
            }
          }
          Variations {
            UndigitalCabinetryVariation {
              status
              featureImagePath
              imagePosition
              objectID
              rrPrice
              name
              Styles {
                UndigitalProductStyle {
                  name
                }
              }
              Gallery {
                UndigitalGalleryImage {
                  imagePath
                  caption
                }
              }
            }
          }
          Disclaimer {
            UndigitalTextAsset {
              title
            }
          }
        }
      }
    }
  }
`

const mapStateToProps = (state) => ({
  currentBoards: state.board.all,
})

export default connect(mapStateToProps, null)(CabinetryDetail)
